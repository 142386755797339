import { useContext, useEffect, useState } from 'react';
import firebase from 'firebase';

import { userContext } from '../context/userContext';
import { getUser } from '../util/functions';
import { DESIGNER, DEVELOPER } from '../util/constants';

export function useGetData() {
	const [fetching, setFetching] = useState(true);
	const [tungaUser, setTungaUser] = useState(null);
	const { user } = useContext(userContext);

	let storage = firebase.storage();
	let storageRef = storage.ref();
	let imageRef;
	let qrRef;

	useEffect(() => {
		(async () => {
			const userData = await getUser({ userTungaID: user.tunga_id });
			const isAdminUser = ![DEVELOPER, DESIGNER].includes(userData?.role);

			setTungaUser({ ...userData, isAdminUser });
		})();
	}, [user]);

	useEffect(() => {
		let isMounted = true;

		if (tungaUser && fetching) {
			imageRef = storageRef.child(`${tungaUser.fs_image_destination}`);
			qrRef = storageRef.child(`${tungaUser.fs_qr_destination}`);

			imageRef
				.getDownloadURL()
				.then(function (url) {
					if (isMounted) {
						setTungaUser({ ...tungaUser, profile_image: url });
						setFetching(false);
					}
				})
				.catch((err) => {
					// console.log('download error', err);
					setFetching(false);
				});

			qrRef
				.getDownloadURL()
				.then(function (url) {
					if (isMounted) {
						setTungaUser({ ...tungaUser, barcode_image: url });
						setFetching(false);
					}
				})
				.catch((err) => {
					// console.log('download error', err);
					setFetching(false);
				});
		}
		return () => {
			isMounted = false;
		};
	}, [tungaUser, fetching]);

	return { fetching, tungaUser, setTungaUser, setFetching };
}
