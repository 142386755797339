import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import {
	clearAuthTokens,
	localAuthService,
	loginService,
	setLocalAuthTokens,
} from '../services/user';
import { getUser } from '../util/functions';
import { DESIGNER, DEVELOPER } from '../util/constants';

export const userContext = createContext();

export function UserContextProvider({ children }) {
	const localAuth = localAuthService();
	const history = useHistory();

	const [user, setUser] = useState(localAuth.user);
	const [isLoggedIn, setIsLoggedIn] = useState(localAuth.isLoggedIn);
	const [isAdminUser, setIsAdminUser] = useState(localAuth.isAdminUser);
	const [connError, setConnError] = useState(false);
	const [logingIn, setLogingIn] = useState(false);
	const [loginError, setLoginError] = useState(false);

	const loginUser = async (data) => {
		try {
			setLogingIn(true);
			setLoginError(false);
			setConnError(false);

			const { access, refresh } = await loginService(data);

			if (access) {
				const decoded = jwtDecode(access);
				const userData = await getUser({ userTungaID: decoded?.tunga_id });
				const isAdminUser = ![DEVELOPER, DESIGNER].includes(userData?.role);
				const redirectUrl = isAdminUser ? '/dashboard' : '/id_card';

				setLocalAuthTokens({ access: access, refresh: refresh, isAdminUser });
				setIsLoggedIn(true);
				setIsAdminUser(isAdminUser);
				setUser(decoded);

				history.push(redirectUrl);
			} else {
				setLoginError(true);
			}
		} catch (err) {
			setConnError(true);
		} finally {
			setLogingIn(false);
		}
	};

	const logoutUser = () => {
		clearAuthTokens();
		setIsLoggedIn(false);
		setLoginError(false);
		setIsAdminUser(null);
		setConnError(false);
		setUser({});
	};

	return (
		<userContext.Provider
			value={{
				user: user,
				isLoggedIn: isLoggedIn,
				isAdminUser: isAdminUser,
				connError: connError,
				loginError: loginError,
				logingIn: logingIn,
				setUser,
				loginUser: loginUser,
				logoutUser: logoutUser,
			}}
		>
			{children}
		</userContext.Provider>
	);
}

UserContextProvider.propTypes = {
	children: PropTypes.any,
};
