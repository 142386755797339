import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom/';

const Desktop = ({ children }) => {
	const location = useLocation();

	const isDashboard = location.pathname === '/dashboard';

	return (
		<div
			className='mx-auto position-relative'
			style={{
				maxWidth: isDashboard ? '960px' : '480px',
				width: '100%',
			}}
		>
			{children}
		</div>
	);
};

Desktop.propTypes = {
	children: PropTypes.node,
	isDashboard: PropTypes.bool,
};

export default Desktop;
