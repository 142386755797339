import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { userContext } from '../../context/userContext';

export default function ProtectedRoute({
	path,
	component: Component,
	...rest
}) {
	const { isLoggedIn } = useContext(userContext);

	return (
		<Route
			path={path}
			{...rest}
			render={(props) => {
				if (isLoggedIn) {
					return <Component {...props} />;
				} else {
					return <Redirect to='/' />;
				}
			}}
		/>
	);
}

ProtectedRoute.propTypes = {
	path: PropTypes.string.isRequired,
	component: PropTypes.any,
};
