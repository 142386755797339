export const BASE_URL = '/';
export const HOME_URL = '/id_card';
export const DASHBOARD_URL = '/dashboard';
export const QRCODE_URL = '/barcode';
export const PRINT_URL = '/print';

export const DEVELOPER = 'Developer';
export const DESIGNER = 'Designer';

/** Firebase */
export const USERS_COLLECTION = process.env.REACT_APP_usersCollection;

/** PWA service worker url */
export const SERVICE_WORKE_DOC_URL = process.env.REACT_APP_baseApi;
