import firebase from 'firebase';

import { USERS_COLLECTION } from './constants';

const getUser = async ({ userTungaID }) => {
	const db = firebase.firestore();

	try {
		const doc = await db.collection(USERS_COLLECTION).doc(userTungaID).get();
		if (doc.exists) {
			return doc.data();
		}
		return null;
	} catch (error) {}
};

export { getUser };
